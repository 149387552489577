import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import privacypdf from "../../assets/privacy-policy.pdf";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const PrivacyPolicy = () => {
	const [numPages, setNumPages] = useState(null);

	const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
		setNumPages(nextNumPages);
	};

	// const options = {
	//     cMapUrl: 'cmaps/',
	//     cMapPacked: true,
	//   };

	const { height, width } = useWindowDimensions();

	return (
		<div
			style={{
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<Document file={privacypdf} onLoadSuccess={onDocumentLoadSuccess}>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						width={width}
					/>
				))}
			</Document>
		</div>
	);
};

export default PrivacyPolicy;
