import "./index.css";
import "./App.css";

import Home from "./pages/Home";
import ResponsiveNavbar from "./components/ResponsiveNavbar";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UserAgreement from "./pages/UserAgreement";

function App() {
	return (
		<Router>
			<div className="App">
				<Switch>
					<Route exact path="/privacy-policy">
						<ResponsiveNavbar />
						<PrivacyPolicy />
					</Route>
					<Route exact path="/user-agreement">
						<ResponsiveNavbar />
						<UserAgreement />
					</Route>
					<Route exact path="/">
						<ResponsiveNavbar />
						<Home />
					</Route>
				</Switch>
			</div>
		</Router>
	);
}

export default App;
