export const LockedIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 60 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.7392 23.8716H9.85489C4.46432 23.8716 0.0949707 28.241 0.0949707 33.6316V64.6789C0.0949707 70.0694 4.46432 74.4389 9.85489 74.4389H49.7392C55.1298 74.4389 59.4992 70.0694 59.4992 64.6789V33.6316C59.4992 28.241 55.1298 23.8716 49.7392 23.8716ZM29.6744 54.0647C26.9644 54.0647 24.7649 51.8652 24.7649 49.1552C24.7649 46.4452 26.9644 44.2458 29.6744 44.2458C32.3844 44.2458 34.5838 46.4452 34.5838 49.1552C34.5838 51.8652 32.3844 54.0647 29.6744 54.0647Z"
        fill="#fff"
      />
      <path
        d="M14.3324 24.1169V16.9982C14.3324 8.45576 21.2547 1.53345 29.7971 1.53345C38.3396 1.53345 45.2619 8.45576 45.2619 16.9982V24.1169"
        stroke="#fff"
        stroke-width="1.47283"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const KeyboardIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 69 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.4101 0.926538C64.6601 0.931447 67.2818 2.93942 68.0526 5.98327C68.1949 6.55277 68.2685 29.8383 68.2636 40.8943C68.2636 44.6697 65.2689 47.6546 61.4886 47.6546C43.7557 47.6595 26.0228 47.6595 8.28991 47.6546C4.4851 47.6546 1.51977 44.6991 1.51977 40.9139C1.51977 29.8579 1.51489 18.8018 1.54434 7.75068C1.55416 3.75439 4.41638 0.936362 8.42739 0.931452C17.2644 0.916724 52.5927 0.921628 61.4101 0.926538Z"
        stroke="#fff"
        stroke-width="1.47283"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M34.9189 40.9138C39.1263 40.9138 43.3336 40.9089 47.541 40.9236C48.0369 40.9236 48.2578 40.8205 48.248 40.2657C48.2185 38.4787 48.2234 36.6916 48.248 34.9046C48.2529 34.4088 48.0908 34.2615 47.5999 34.2615C39.1409 34.2713 30.6771 34.2762 22.2181 34.2615C21.683 34.2615 21.5357 34.4382 21.5456 34.9537C21.575 36.6671 21.5848 38.3854 21.5406 40.0988C21.5259 40.7518 21.7223 40.9334 22.3703 40.9285C26.5532 40.8991 30.736 40.9138 34.9189 40.9138Z"
        fill="#fff"
      />
      <path
        d="M11.5351 14.2899C11.5351 15.208 11.5499 16.1211 11.5302 17.0392C11.5204 17.4614 11.6628 17.6185 12.0949 17.6136C13.9261 17.594 15.7573 17.594 17.5885 17.6136C18.0647 17.6185 18.1875 17.4418 18.1825 16.995C18.1629 15.1883 18.1629 13.3768 18.1825 11.5701C18.1875 11.1184 18.0451 10.9515 17.5787 10.9564C15.772 10.976 13.9604 10.981 12.1537 10.9564C11.6431 10.9515 11.5106 11.1381 11.5253 11.6143C11.5548 12.5029 11.5351 13.3964 11.5351 14.2899Z"
        fill="#fff"
      />
      <path
        d="M21.5554 14.2163C21.5554 15.154 21.5701 16.0966 21.5505 17.0343C21.5406 17.4565 21.683 17.6136 22.115 17.6136C23.9708 17.594 25.8266 17.5989 27.6823 17.6136C28.0849 17.6185 28.2076 17.4565 28.2076 17.0686C28.1978 15.2129 28.1978 13.3571 28.2076 11.5013C28.2125 11.1135 28.08 10.9564 27.6823 10.9564C25.8266 10.9711 23.9708 10.9711 22.115 10.9564C21.6781 10.9515 21.5456 11.1233 21.5554 11.5406C21.5652 12.4292 21.5554 13.3227 21.5554 14.2163Z"
        fill="#fff"
      />
      <path
        d="M34.9728 10.9615C34.0351 10.9615 33.0925 10.9762 32.1548 10.9566C31.7424 10.9467 31.5657 11.0744 31.5706 11.5162C31.5853 13.372 31.5853 15.2278 31.5706 17.0835C31.5657 17.4861 31.7228 17.6138 32.1107 17.6088C33.9664 17.599 35.8221 17.5941 37.6779 17.6088C38.1345 17.6138 38.2278 17.4174 38.2229 17.0148C38.2082 15.1836 38.2082 13.3524 38.2229 11.5211C38.2278 11.0891 38.0706 10.9418 37.6484 10.9566C36.7549 10.9762 35.8614 10.9615 34.9728 10.9615Z"
        fill="#fff"
      />
      <path
        d="M48.2332 14.3587C48.2332 13.4406 48.2136 12.5275 48.2431 11.6094C48.2578 11.1332 48.1252 10.9417 47.6146 10.9515C45.808 10.9761 44.0013 10.9712 42.1897 10.9515C41.7233 10.9466 41.5809 11.1135 41.5858 11.5652C41.6055 13.3719 41.6055 15.1835 41.5858 16.9901C41.5809 17.4369 41.7086 17.6136 42.1848 17.6087C44.016 17.5891 45.8473 17.5891 47.6785 17.6087C48.1106 17.6136 48.2529 17.4565 48.2431 17.0343C48.2185 16.1457 48.2332 15.2522 48.2332 14.3587Z"
        fill="#fff"
      />
      <path
        d="M54.9199 17.6038C55.8331 17.6038 56.7511 17.594 57.6691 17.6087C58.0668 17.6185 58.273 17.5252 58.2681 17.0687C58.2484 15.2129 58.2533 13.3571 58.2681 11.5013C58.2681 11.1184 58.1454 10.9515 57.7428 10.9564C55.8871 10.9711 54.0313 10.9711 52.1804 10.9564C51.7435 10.9515 51.611 11.1233 51.6159 11.5357C51.6306 13.3669 51.6355 15.1982 51.611 17.0294C51.606 17.5203 51.8171 17.6185 52.2491 17.6087C53.1377 17.5891 54.0264 17.6038 54.9199 17.6038Z"
        fill="#fff"
      />
      <path
        d="M18.1727 24.2856C18.1727 23.3675 18.1629 22.4544 18.1776 21.5363C18.1825 21.1681 18.0892 20.9717 17.667 20.9767C15.7867 20.9914 13.9113 20.9865 12.031 20.9767C11.6775 20.9767 11.5253 21.0994 11.5253 21.4676C11.5351 23.3479 11.5351 25.2233 11.5253 27.1037C11.5204 27.5111 11.6873 27.6289 12.0703 27.6289C13.9261 27.6191 15.7819 27.6142 17.6327 27.6289C18.0893 27.6339 18.1825 27.4277 18.1727 27.03C18.1629 26.1168 18.1727 25.1988 18.1727 24.2856Z"
        fill="#fff"
      />
      <path
        d="M28.1977 24.3053C28.1977 23.3872 28.183 22.474 28.2027 21.556C28.2125 21.1387 28.08 20.9718 27.6381 20.9767C25.7824 20.9963 23.9265 20.9914 22.0708 20.9767C21.6682 20.9718 21.5406 21.1338 21.5455 21.5216C21.5554 23.3774 21.5554 25.2332 21.5455 27.0889C21.5406 27.4768 21.6731 27.6339 22.0757 27.629C23.9315 27.6142 25.7873 27.6142 27.643 27.629C28.08 27.6339 28.2125 27.467 28.2027 27.0496C28.183 26.1365 28.1977 25.2184 28.1977 24.3053Z"
        fill="#fff"
      />
      <path
        d="M38.218 24.3102C38.218 23.4167 38.1984 22.5232 38.2278 21.6346C38.2426 21.1633 38.1149 20.9669 37.5994 20.9718C35.7927 20.9963 33.986 20.9914 32.1745 20.9718C31.713 20.9669 31.5657 21.1289 31.5706 21.5855C31.5902 23.3921 31.5902 25.2038 31.5706 27.0104C31.5657 27.4572 31.6933 27.6388 32.1646 27.6339C33.9958 27.6143 35.827 27.6143 37.6583 27.6339C38.0903 27.6388 38.2376 27.4817 38.2229 27.0595C38.1984 26.1414 38.218 25.2234 38.218 24.3102Z"
        fill="#fff"
      />
      <path
        d="M41.5956 24.2366C41.5956 25.1743 41.6103 26.1169 41.5907 27.0546C41.5809 27.4768 41.7282 27.6339 42.1602 27.629C43.9915 27.6143 45.8227 27.6094 47.6539 27.629C48.1301 27.6339 48.2528 27.4523 48.2479 27.0055C48.2283 25.1988 48.2283 23.3922 48.2479 21.5806C48.2528 21.1289 48.1105 20.962 47.6441 20.9669C45.8374 20.9865 44.0307 20.9914 42.224 20.9669C41.7134 20.962 41.5759 21.1534 41.5907 21.6248C41.6152 22.4986 41.5956 23.3676 41.5956 24.2366Z"
        fill="#fff"
      />
      <path
        d="M58.2584 24.32C58.2584 23.4264 58.2387 22.5329 58.2681 21.6443C58.2828 21.1828 58.1699 20.9668 57.6495 20.9766C55.8428 21.0012 54.0363 20.9963 52.2296 20.9816C51.7779 20.9766 51.611 21.119 51.6159 21.5854C51.6355 23.3921 51.6355 25.1988 51.6159 27.0104C51.611 27.4473 51.719 27.6486 52.2001 27.6388C54.0313 27.6191 55.8625 27.6191 57.6937 27.6388C58.116 27.6437 58.2828 27.5062 58.2681 27.0742C58.2387 26.1512 58.2584 25.2331 58.2584 24.32Z"
        fill="#fff"
      />
      <path
        d="M34.9189 40.9138C30.736 40.9138 26.5532 40.8991 22.3703 40.9285C21.7223 40.9334 21.5259 40.7518 21.5406 40.0988C21.5848 38.3854 21.5701 36.6671 21.5456 34.9537C21.5357 34.4382 21.683 34.2615 22.2181 34.2615C30.6771 34.2762 39.1409 34.2762 47.5999 34.2615C48.0908 34.2615 48.2529 34.4088 48.248 34.9046C48.2234 36.6916 48.2185 38.4787 48.248 40.2657C48.2578 40.8205 48.0369 40.9236 47.541 40.9236C43.3336 40.904 39.1263 40.9138 34.9189 40.9138Z"
        fill="#fff"
      />
      <path
        d="M11.5351 14.2899C11.5351 13.3964 11.5547 12.5029 11.5253 11.6143C11.5105 11.1381 11.648 10.9466 12.1537 10.9564C13.9604 10.981 15.772 10.981 17.5787 10.9564C18.0451 10.9515 18.1874 11.1184 18.1825 11.5701C18.1629 13.3768 18.1629 15.1883 18.1825 16.995C18.1874 17.4418 18.0598 17.6185 17.5885 17.6136C15.7573 17.594 13.9261 17.594 12.0948 17.6136C11.6628 17.6185 11.5204 17.4614 11.5302 17.0392C11.5498 16.1211 11.5351 15.2031 11.5351 14.2899Z"
        fill="#fff"
      />
      <path
        d="M21.5554 14.2163C21.5554 13.3228 21.5701 12.4292 21.5505 11.5406C21.5407 11.1233 21.6732 10.9564 22.1101 10.9564C23.9659 10.9711 25.8217 10.9711 27.6774 10.9564C28.08 10.9515 28.2076 11.1135 28.2027 11.5013C28.1929 13.3571 28.1929 15.2129 28.2027 17.0687C28.2076 17.4565 28.08 17.6136 27.6774 17.6136C25.8217 17.5989 23.9659 17.5989 22.1101 17.6136C21.6732 17.6185 21.5357 17.4565 21.5456 17.0343C21.5701 16.0966 21.5554 15.154 21.5554 14.2163Z"
        fill="#fff"
      />
      <path
        d="M34.9728 10.9612C35.8663 10.9612 36.7598 10.976 37.6484 10.9563C38.0706 10.9465 38.2278 11.0889 38.2229 11.5209C38.2082 13.3521 38.2082 15.1834 38.2229 17.0146C38.2278 17.4172 38.1296 17.6135 37.6779 17.6086C35.8221 17.589 33.9664 17.5939 32.1107 17.6086C31.7228 17.6135 31.5657 17.481 31.5706 17.0833C31.5853 15.2275 31.5853 13.3718 31.5706 11.516C31.5657 11.0741 31.7424 10.9465 32.1548 10.9563C33.0925 10.976 34.0302 10.9612 34.9728 10.9612Z"
        fill="#fff"
      />
      <path
        d="M48.2332 14.3587C48.2332 15.2522 48.2185 16.1457 48.2381 17.0343C48.2479 17.4565 48.1056 17.6136 47.6736 17.6087C45.8423 17.5891 44.0111 17.5891 42.1799 17.6087C41.7086 17.6136 41.5809 17.4369 41.5809 16.9901C41.6005 15.1835 41.6005 13.3719 41.5809 11.5652C41.576 11.1135 41.7232 10.9466 42.1847 10.9515C43.9914 10.9712 45.803 10.9761 47.6097 10.9515C48.1203 10.9466 48.2528 11.1381 48.2381 11.6094C48.2136 12.5275 48.2332 13.4406 48.2332 14.3587Z"
        fill="#fff"
      />
      <path
        d="M54.9199 17.6038C54.0264 17.6038 53.1329 17.5891 52.2443 17.6087C51.8171 17.6185 51.6011 17.5203 51.606 17.0294C51.6306 15.1982 51.6207 13.3669 51.6109 11.5357C51.606 11.1184 51.7385 10.9515 52.1755 10.9564C54.0312 10.9711 55.887 10.9711 57.7379 10.9564C58.1405 10.9515 58.2631 11.1184 58.2631 11.5013C58.2533 13.3571 58.2484 15.2129 58.2631 17.0687C58.268 17.5252 58.0619 17.6185 57.6642 17.6087C56.751 17.5891 55.8379 17.6038 54.9199 17.6038Z"
        fill="#fff"
      />
      <path
        d="M18.1727 24.2856C18.1727 25.2037 18.1629 26.1169 18.1776 27.0349C18.1874 27.4326 18.0941 27.6388 17.6375 27.6339C15.7817 27.6142 13.9261 27.6191 12.0752 27.6339C11.6923 27.6388 11.5253 27.516 11.5302 27.1086C11.5449 25.2282 11.54 23.3528 11.5302 21.4725C11.5302 21.1043 11.6775 20.9767 12.0359 20.9816C13.9162 20.9914 15.7916 20.9914 17.6719 20.9816C18.0892 20.9767 18.1874 21.1731 18.1825 21.5413C18.1629 22.4544 18.1727 23.3676 18.1727 24.2856Z"
        fill="#fff"
      />
      <path
        d="M28.1977 24.3053C28.1977 25.2233 28.183 26.1365 28.2026 27.0545C28.2125 27.4718 28.08 27.6387 27.643 27.6338C25.7873 27.6142 23.9314 27.6191 22.0757 27.6338C21.6731 27.6387 21.5455 27.4817 21.5455 27.0938C21.5554 25.238 21.5554 23.3823 21.5455 21.5265C21.5455 21.1436 21.6731 20.9816 22.0708 20.9816C23.9265 20.9963 25.7823 20.9963 27.6381 20.9816C28.075 20.9766 28.2125 21.1436 28.2026 21.5609C28.183 22.4691 28.1977 23.3872 28.1977 24.3053Z"
        fill="#fff"
      />
      <path
        d="M38.218 24.3102C38.218 25.2283 38.2033 26.1414 38.2229 27.0595C38.2327 27.4817 38.0903 27.6388 37.6583 27.6339C35.827 27.6143 33.9959 27.6143 32.1646 27.6339C31.6884 27.6388 31.5657 27.4572 31.5706 27.0104C31.5902 25.2037 31.5902 23.3921 31.5706 21.5855C31.5657 21.1289 31.713 20.9669 32.1745 20.9718C33.9811 20.9914 35.7878 20.9963 37.5994 20.9718C38.11 20.962 38.2426 21.1584 38.2278 21.6346C38.1935 22.5232 38.218 23.4167 38.218 24.3102Z"
        fill="#fff"
      />
      <path
        d="M41.5956 24.2366C41.5956 23.3676 41.6152 22.4986 41.5858 21.6297C41.571 21.1535 41.7085 20.962 42.2191 20.9718C44.0258 20.9963 45.8325 20.9914 47.6391 20.9718C48.1055 20.9669 48.2479 21.1338 48.243 21.5855C48.2234 23.3921 48.2234 25.1988 48.243 27.0104C48.2479 27.4572 48.1252 27.6388 47.649 27.6339C45.8177 27.6143 43.9866 27.6143 42.1553 27.6339C41.7233 27.6388 41.5759 27.4817 41.5858 27.0595C41.6103 26.1169 41.5956 25.1792 41.5956 24.2366Z"
        fill="#fff"
      />
      <path
        d="M58.2584 24.32C58.2584 25.2332 58.2436 26.1512 58.2632 27.0644C58.2731 27.5013 58.1062 27.6339 57.6889 27.629C55.8576 27.6142 54.0265 27.6093 52.1952 27.629C51.7092 27.6339 51.6061 27.4326 51.611 27.0006C51.6258 25.1939 51.6307 23.3872 51.611 21.5756C51.6061 21.1092 51.773 20.9668 52.2247 20.9718C54.0314 20.9914 55.8381 20.9963 57.6448 20.9669C58.1652 20.9571 58.278 21.1731 58.2632 21.6345C58.2387 22.533 58.2584 23.4265 58.2584 24.32Z"
        fill="#fff"
      />
    </svg>
  );
};

export const AddPeopleIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 88 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4156 55.8117H1.54181V46.4837C1.54672 43.867 2.58256 41.3534 4.43342 39.5025C6.27937 37.6467 8.77835 36.6108 11.3853 36.6108H22.5346C24.464 36.6108 26.3394 37.1803 27.9301 38.2261"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
      />
      <path
        d="M16.3437 32.3883C14.5616 32.3883 12.8139 31.8629 11.3312 30.8712C9.84855 29.8795 8.68991 28.4705 8.0075 26.8259C7.32509 25.1763 7.14353 23.3647 7.4921 21.617C7.84067 19.8692 8.69972 18.2589 9.95653 16.9972C11.2183 15.7354 12.8237 14.8763 14.5764 14.5277C16.3242 14.1791 18.1406 14.3559 19.7902 15.0383C21.4397 15.7207 22.8487 16.8744 23.8355 18.362C24.8272 19.8446 25.3575 21.5875 25.3575 23.3745C25.3575 24.5577 25.1268 25.7311 24.6702 26.8259C24.2186 27.9207 23.5557 28.9124 22.7162 29.7519C21.8767 30.5914 20.885 31.2542 19.7902 31.7058C18.7003 32.1575 17.5269 32.3883 16.3437 32.3883Z"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
      />
      <path
        d="M41.3818 32.3935C39.1284 32.3984 36.9241 31.7209 35.0487 30.4444C33.1733 29.168 31.7103 27.3515 30.8462 25.2257C29.9821 23.0999 29.7515 20.7581 30.1884 18.4997C30.6254 16.2414 31.7054 14.1647 33.3009 12.5348C34.8916 10.9048 36.9192 9.7953 39.1334 9.34364C41.3427 8.89197 43.6353 9.12271 45.7169 10.0015C47.7985 10.8803 49.5806 12.3728 50.8325 14.2874C52.0844 16.2021 52.7522 18.4556 52.7522 20.7581C52.7522 23.8412 51.5543 26.7967 49.4236 28.9814C47.288 31.1563 44.3962 32.3886 41.3818 32.3935Z"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
      />
      <path
        d="M59.9642 41.4857C58.6043 40.165 57.0136 39.1242 55.2806 38.3976C53.4199 37.6121 51.3923 37.1997 49.3156 37.1948H33.4728C31.5532 37.1948 29.6729 37.5483 27.9301 38.2307C26.035 38.9524 24.2922 40.057 22.8292 41.4808C20.0063 44.2251 18.4204 47.9514 18.4204 51.8397V65.704H64.3827V51.8397C64.3778 47.9514 62.7871 44.235 59.9642 41.4857Z"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
      />
      <path
        d="M73.0332 28.7115C80.7312 28.7115 86.9661 22.4716 86.9661 14.7785C86.9661 7.08536 80.7263 0.845459 73.0332 0.845459C65.3401 0.845459 59.1001 7.08536 59.1001 14.7785C59.1001 22.4765 65.3352 28.7115 73.0332 28.7115Z"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
      />
      <path
        d="M73.0332 8.91162V20.6501"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M78.9048 14.7781H67.1614"
        stroke="#fff"
        stroke-width="3.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
    </svg>
  );
};
