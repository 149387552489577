import { LockedIcon, KeyboardIcon, AddPeopleIcon } from "./Icons";

export const MissionCards = [
	{
		header: "A lack of awareness can put people at a disadvantage.",
		body: "In a world where news is not difficult to come by, readers are overwhelmed by the amount of information that is continuously thrown at them. Alirtify narrows this down to just the news that you need to pay attention to. It does this by geo-tagging the news to people and places that you care about.",
		icon: <LockedIcon />,
	},
	{
		header: "Awareness brings peace of mind.",
		body: "With a plethora of digital-first news outlets and social media platforms readily available, it can be very challenging to narrow the information down to what you absolutely need to know. Alirtify minimizes your stress by providing you with timely alerts on events that affect people and places you care about.",
		icon: <KeyboardIcon />,
	},
	{
		header: "We can change the world together.",
		body: "In a society where the most popular voices are the ones that are likely to be heard and paid attention to, local news can get lost. Alirtify provides society with a unique opportunity to push local news to the forefront and change the narrative on what truly matters to our local communities.",
		icon: <AddPeopleIcon />,
	},
];
