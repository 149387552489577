/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
	MapViewContainer,
	MapViewWrapper,
	MaskContainer,
	BackLayer,
	BackLayerImage,
	MapViewContentContainer,
	MapViewContentWrapper,
	MapViewContentHeader,
	MapViewContentPopups,
	MapViewContentInfo,
	ContentInfoGrid,
	GridItem,
	BackImage,
	GridWrapper,
} from "./MapViewElements";
import SvgMask from "./SvgMask";
import { MuiGrid } from "./AnimatedGrid";
import card2 from "../../assets/images/card2.png";
import person10 from "../../assets/images/faces/Person10.png";

const MapView = () => {
	const [offsetY, setOffsetY] = useState(0);
	const handleScroll = () => setOffsetY(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	const img = require("../../assets/images/card2.png");

	return (
		<MapViewContainer>
			<BackLayer>
				<BackLayerImage offsetY={offsetY} />
			</BackLayer>
			<MapViewWrapper>
				<MaskContainer>
					<SvgMask backgroundColor="white" />
				</MaskContainer>
				<MapViewContentContainer>
					<MapViewContentWrapper>
						<MapViewContentHeader>
							<h2 style={{ fontSize: 48 }}>
								Your world is <br /> only a click away.{" "}
							</h2>
						</MapViewContentHeader>
						<div style={{ height: 350 }}>
							{/* <img
								src={person10}
								alt="person10"
								width="76"
								height="76"
								style={{ borderRadius: 38 }}
							/> */}
						</div>
						<MapViewContentPopups>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								<img src={card2} alt="feedItem1" style={{ borderRadius: 40 }} />
							</div>
						</MapViewContentPopups>
					</MapViewContentWrapper>
				</MapViewContentContainer>
			</MapViewWrapper>

			<MapViewContentInfo>
				<MuiGrid />
			</MapViewContentInfo>
		</MapViewContainer>
	);
};

export default MapView;
