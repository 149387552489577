import { MicIcon, GlobeIcon, VideoIcon } from "./Icons";

export const DetailsCards = [
	{
		header:
			"Connect with people that live near to the events and get the real story.",
		body: "Alirtify provides users with virtual news rooms where you can get the truth about what happened from the people on the ground.",
		icon: <GlobeIcon />,
	},
	{
		header: "Join the battle against fake news through our community.",
		body: "You can join our virtual community of fact checkers and challenge the facts on the news to help us debunk fake news.",
		icon: <MicIcon />,
	},
	{
		header:
			"Extend the life-cycle of news that you care about by joining live conversations and debates.",
		body: "Sometimes what you truly care about does not make the headlines. Through Alirtify, you can rally around a topic and get the larger community to notice and pay attention.",
		icon: <VideoIcon />,
	},
];
